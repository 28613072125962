<!-- <template>
  <v-container fluid> -->
    <!-- <button @click="generarReporte">
      Generar Reporte
    </button> -->
    <!-- <v-data-table
      :headers="headers"
      :items="listCashOperation"
      item-key="id"
      sort-by="date"
    >
    </v-data-table> -->
  <!-- </v-container>
</template> -->

<script>

import axios from 'axios'
import jsPDF from 'jspdf'
import autoTable from 'jspdf-autotable'

export default {
  data: () => ({
    generalBalance: null,
    desserts: [],
    ingresosToday: null,
    margenSubtitle: null,
    egresosToday: null,
    saldoToday: null,
    subtitle: null,
    listCashOperation: [],
    headers: [
      {
        text: 'Id',
        align: 'start',
        sortable: true,
        value: 'id',
      },
      { text: 'Fecha', value: 'date' },
      // eslint-disable-next-line no-useless-concat
      { text: 'Descripción', value: 'description' },
      { text: 'Monto', value: 'amount' },
    ],
    transferenciaData: [],

    bienesData: [],
    reportTitle: null,
  }),

  // watch: {
  //   dialogDelete(val) {
  //     val || this.closeDelete()
  //   },
  // },

  async mounted() {
    if (this.$route.params.fromDate === this.$route.params.toDate) {
      this.subtitle = `(${this.moment(this.$route.params.fromDate).format('DD/MM/YYYY')})`
      this.reportTitle = 'Listado de Caja Diaria'
      this.margenSubtitle = 0.47
    } else {
      this.subtitle = `(${this.moment(this.$route.params.fromDate).format('DD/MM/YYYY')} hasta ${this.moment(this.$route.params.toDate).format('DD/MM/YYYY')})`
      this.reportTitle = 'Listado de Caja Mensual'
      this.margenSubtitle = 0.37
    }

    await this.getReport()
    await this.generarReporte()
    this.$router.push({ name: 'dashboard' })
  },
  methods: {
    async generarReporte() {
    //   const doc = new jsPDF({
    //     orientation: "portrait",
    //     unit: "px",
    //     //format: [4, 2],
    //     format: "A4",
    //     margin: { top: 20 },
    //   });
      const doc = new jsPDF('p', 'pt')

      const logo = require('../../assets/images/logos/efectivo-ya-png.png')

      const imgLogo = new Image()
      imgLogo.src = logo
      doc.addImage(imgLogo, 'PNG', 40, 40, 80, 40)

      const arregloDatos = Object(this.desserts).map(obj => {
        const datos = [
          obj.date,
          obj.client,
          obj.description,
          obj.amountIn,
          obj.amountOut,
          obj.balance,

        ]

        return datos
      })
      const arregloDatosTransferencia = Object(this.transferenciaData).map(obj => {
        const datos = [
          obj.date,
          obj.client,
          obj.description,
          obj.amountIn,
          obj.amountOut,
          obj.balance,

        ]

        return datos
      })
      const arregloDatosBienes = Object(this.bienesData).map(obj => {
        const datos = [
          obj.date,
          obj.client,
          obj.description,
          obj.amountIn,
          obj.amountOut,
          obj.balance,

        ]

        return datos
      })
      const encabezado = ['Fecha y Hora', 'Cliente', 'Descripcion', 'Entrada', 'Salida', 'Saldo']

      const titulo = this.reportTitle
      doc.text(titulo, doc.internal.pageSize.width * 0.40, 80, {
        aling: 'center',
      })
      doc.text(this.subtitle, doc.internal.pageSize.width * this.margenSubtitle, 100, {
        aling: 'center',
      })

      const emisor = `Emitido por ${this.$store.getters.usuario.name}`

      const espacioEntreLineas = 20

      const fechaEmision = `Fecha de Emision: ${this.moment().format('DD/MM/YYYY')}`

      doc.text(
        fechaEmision,
        doc.internal.pageSize.width * 0.05,
        120 + espacioEntreLineas * 1,
        {
          aling: 'center',
          styles: { fontSize: 19 },
          fontSize: 1,
        },
      )

      doc.text(
        emisor,
        doc.internal.pageSize.width * 0.05,
        135 + espacioEntreLineas * 1,
        {
          aling: 'right',
          styles: { fontSize: 18 },
          fontSize: 20,
        },
      )


      // body: [
      //     ['Caja Anterior', `$ ${parseFloat(this.generalBalance.cashRegisterBefore)}`, `$ ${parseFloat(this.generalBalance.transferenciaBefore)}`, `$ ${parseFloat(this.generalBalance.bienesBefore)}`, `$ ${(parseFloat(this.generalBalance.cashRegisterBefore) + parseFloat(this.generalBalance.transferenciaBefore) + parseFloat(this.generalBalance.bienesBefore)).toFixed(2)}`],
      //     ['Entrada', `$ ${parseFloat(this.generalBalance.amountIn)}`, `$ ${parseFloat(this.generalBalance.amountInTransferencia)}`, `$ ${parseFloat(this.generalBalance.amountInBienes)}`, `$ ${(parseFloat(this.generalBalance.amountIn) + parseFloat(this.generalBalance.amountInTransferencia) + parseFloat(this.generalBalance.amountInBienes).toFixed(2))}`],
      //     ['Salida', `- $ ${parseFloat(this.generalBalance.amountOut)}`, '$ 0', '$ 0', `- $ ${parseFloat(this.generalBalance.amountOut)}`],
      //     ['Saldo', `$ ${parseFloat(this.generalBalance.balance)}`, `$ ${parseFloat(this.generalBalance.balanceTransferencia)}`, `$ ${parseFloat(this.generalBalance.balanceBienes)}`, `$ ${(parseFloat(this.generalBalance.balance) + parseFloat(this.generalBalance.balanceTransferencia) + parseFloat(this.generalBalance.balanceBienes)).toFixed(2)}`],

      //   ],

      doc.autoTable({
        // html: '#my-table',
        head: [
          ['', 'Efectivo', 'Transferencia', 'Bienes', 'Saldo'],
        ],
      body: [
          ['Caja Anterior', `$ ${parseFloat(this.generalBalance.cashRegisterBefore).toFixed(2)}`, `$ ${parseFloat(this.generalBalance.transferenciaBefore).toFixed(2)}`, `$ ${parseFloat(this.generalBalance.bienesBefore).toFixed(2)}`, `$ ${(parseFloat(this.generalBalance.cashRegisterBefore) + parseFloat(this.generalBalance.transferenciaBefore) + parseFloat(this.generalBalance.bienesBefore)).toFixed(2)}`],
          ['Entrada', `$ ${parseFloat(this.generalBalance.amountIn).toFixed(2)}`, `$ ${parseFloat(this.generalBalance.amountInTransferencia).toFixed(2)}`, `$ ${parseFloat(this.generalBalance.amountInBienes).toFixed(2)}`, `$ ${(parseFloat(this.generalBalance.amountIn) + parseFloat(this.generalBalance.amountInTransferencia) + parseFloat(this.generalBalance.amountInBienes)).toFixed(2)}`],
          ['Salida', `- $ ${parseFloat(this.generalBalance.amountOut).toFixed(2)}`, '$ 0', '$ 0', `- $ ${parseFloat(this.generalBalance.amountOut).toFixed(2)}`],
          ['Saldo', `$ ${parseFloat(this.generalBalance.balance).toFixed(2)}`, `$ ${parseFloat(this.generalBalance.balanceTransferencia).toFixed(2)}`, `$ ${parseFloat(this.generalBalance.balanceBienes).toFixed(2)}`, `$ ${(parseFloat(this.generalBalance.balance) + parseFloat(this.generalBalance.balanceTransferencia) + parseFloat(this.generalBalance.balanceBienes)).toFixed(2)}`],

        ],
        columnStyles: {
          1: { halign: 'right' },
          2: { halign: 'right' },
          3: { halign: 'right' },
          4: { halign: 'right' },

        },
        headerStyles: { halign: 'center' },
        theme: 'grid', // tenemos grid, striped o plain por defecto es strpied
        tableWidth: 515,
        aling: 'right',
        margin: { top: 200 },

      })
      doc.setFontSize(12)
      const finalY1 = doc.previousAutoTable.finalY
      doc.setTextColor(110, 112, 108)
      doc.text('Movimientos de Trasferencia', doc.internal.pageSize.width * 0.015, finalY1 + 50)

      autoTable(doc, {
        head: [encabezado],
        headerStyles: { halign: 'center' },

        body: arregloDatosTransferencia,
        startY: finalY1 + 60,
        tableWidth: 575,

        margin: { left: 10 },
        alternateRowStyles: { fillColor: [250, 216, 216] },
        columnStyles: {
          0: { cellWidth: 90, halign: 'left' },
          1: { cellWidth: 155, halign: 'left' },
          2: { cellWidth: 140, halign: 'left' },
          3: { cellWidth: 60, halign: 'right' },
          4: { cellWidth: 60, halign: 'right' },
          5: { cellWidth: 70, halign: 'right' },

        // etc
        },
      })
      const finalY2 = doc.previousAutoTable.finalY

      doc.text('Movimientos en Bienes', doc.internal.pageSize.width * 0.015, finalY2 + 50)
      autoTable(doc, {
        head: [encabezado],
        headerStyles: { halign: 'center' },

        body: arregloDatosBienes,
        startY: finalY2 + 60,
        tableWidth: 575,

        margin: { left: 10 },
        alternateRowStyles: { fillColor: [250, 216, 216] },
        columnStyles: {
          0: { cellWidth: 90, halign: 'left' },
          1: { cellWidth: 155, halign: 'left' },
          2: { cellWidth: 140, halign: 'left' },
          3: { cellWidth: 60, halign: 'right' },
          4: { cellWidth: 60, halign: 'right' },
          5: { cellWidth: 70, halign: 'right' },

        // etc
        },
      })
      const finalY3 = doc.previousAutoTable.finalY

      doc.text('Movimientos en Efectivo', doc.internal.pageSize.width * 0.015, finalY3 + 50)
      autoTable(doc, {
        head: [encabezado],
        headerStyles: { halign: 'center' },

        body: arregloDatos,
        startY: finalY3 + 60,
        tableWidth: 575,

        margin: { left: 10 },
        alternateRowStyles: { fillColor: [250, 216, 216] },
        columnStyles: {
          0: { cellWidth: 90, halign: 'left' },
          1: { cellWidth: 155, halign: 'left' },
          2: { cellWidth: 140, halign: 'left' },
          3: { cellWidth: 60, halign: 'right' },
          4: { cellWidth: 60, halign: 'right' },
          5: { cellWidth: 70, halign: 'right' },

        // etc
        },
      })

      doc.save('Reporte de Caja.pdf', false)
    },

    async getReport() {
      this.url = `${this.$store.getters.urlBase}/api/Report/cashRegisterOperationWithBalance`
      const options = {
        headers: {
          'content-type': 'application/json',
        },
        params: {
          fromDate: this.$route.params.fromDate, // this.moment().format('YYYY-MM-DD'),
          toDate: this.$route.params.toDate, // this.moment().format('YYYY-MM-DD'),
        },

        url: this.url,
      }
      await axios
        .get(this.url, options)
      // eslint-disable-next-line no-return-assign
        .then(response => {
          this.desserts = response.data.cashRegisterOperations
          this.generalBalance = response.data
          this.transferenciaData = response.data.transferencias
          this.bienesData = response.data.bienes
        })
        .catch(error => {

        })
    },

  },
}
</script>
